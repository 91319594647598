
import { USER_PROFILE } from '~/enum/user'
import chatbot from '~/mixins/chatbot'

export default {
  name: 'ChatbotWelcomeMessage',
  mixins: [chatbot],
  props: {
    profile: {
      type: String,
      required: true,
    },
  },
  computed: {
    isHomePage() {
      return this.$route.name === 'locale'
    },
    isLocaleValid() {
      return /^(pt-br|en|es)$/i.test(this.$route.params.locale)
    },
    orientation() {
      if (!process.browser) return {}

      const bottom = `${window.innerHeight - this.position.y - this.size.height / 2}px`
      const right = `${window.innerWidth - this.position.x + 8}px`

      return { bottom, right }
    },
    show() {
      return (
        this.hasChatbotElement &&
        this.canShowWelcomeMessage &&
        this.isHomePage &&
        this.isLocaleValid &&
        this.profile === USER_PROFILE.BUYER
      )
    },
  },
  mounted() {
    window.addEventListener('app-chat:on-open', this.handleClose)
  },
  unmounted() {
    window.removeEventListener('app-chat:on-open', this.handleClose)
  },
  methods: {
    handleClose() {
      this.setWelcomeMessageToggle(false)
      dataLayer.push({
        event: 'custom_event',
        custom_event_name: 'click_chatbot',
        event_details: 'click_notification_close',
      })
    },
    handleOpen() {
      this.openChat()
      dataLayer.push({
        event: 'custom_event',
        custom_event_name: 'click_chatbot',
        event_details: 'click_notification_open',
      })
    },
  },
}
